/* eslint no-lone-blocks: 0 */ // --> OFF
import PhysicalLeaf from './physical_leaf';
import Renderable from './renderable';

import Settings from './Settings';

import * as d3 from 'd3-ease';
import p5 from 'p5';
import PhysicalNode from './physical_node';
import Pipe, { calculateElectricityPipePoints } from './Pipe';
import TableP5 from 'js/Table/TableP5';
import { EnergyGridSummary } from 'js/Table/energy_grid';
import SunImage from '../assets/sun.svg';
import BatteryImage from '../assets/battery.svg';
import removeIcon from '../assets/remove.svg';
import ImageButton from './image_button';
import { circleNodeTextInset, drawNodeText } from './StructureDrawUtils';
import ElectricityNode from './electricity_node';
import * as DataManager from './data/data_manager';
import SliderButton from './slider_button';

/**
 * Objects that are created when a normal physical base is put down on the screen
 * @class Structure
 */
class ElectricitySource extends PhysicalNode {
  size: number;
  imageSize: number;
  name: string;
  context: any;
  icon: string;
  p5Instance: p5;
  createdTimeStamp: number;
  templateFingerPrint: number;
  dirtySince: number;
  dead: boolean;
  baseScale: number;
  shapeLayout: {
    pieChartSize: number;
    rawSize: number;
    imageSize: number;
  };
  electricityPipe: Pipe;
  image: p5.Image;
  connectedNode: PhysicalNode;
  electricityNode: ElectricityNode;
  type: 'battery' | 'pvcell';
  closeButton: ImageButton;
  sliderButton: SliderButton;

  constructor(
    context: any,
    p5Instance: TableP5,
    buildingTemplate: any,
    scale: number,
    type: 'battery' | 'pvcell',
    imagePath: string,
    imageSize: number
  ) {
    super(null);
    this.baseScale = scale;
    this.active = false;
    this.size = 0.1;
    this.imageSize = imageSize;
    this.dead = false;
    this.connectedNode = null;
    this.image = p5Instance.loadImage(imagePath);
    this.type = type;

    this.shapeLayout = {
      pieChartSize: 0,
      rawSize: 0,
      imageSize
    };

    this.dirtySince = context.appNow();
    this.name = this.type === 'battery' ? 'Battery' : 'Solar cell';
    this.context = context;
    Renderable.implement(this, context);

    this.buildingId = buildingTemplate.buildingId;
    this.icon = buildingTemplate.icon;
    this.name = buildingTemplate.name;

    this.p5Instance = p5Instance;
    this.createdTimeStamp = p5Instance.frameCount;
    this.templateFingerPrint = buildingTemplate.fingerprint;

    this.electricityNode = new ElectricityNode();
    this.electricityNode.attenuation = DataManager.getElectricityPotentialFor();
    this.electricityNode.capacity = 2;
    this.electricityNode.scale = this.size;

    this.sliderButton = new SliderButton((newSize) => {
      this.electricityNode.scale = newSize;
      this.touch();
    }, this.size);
    this.sliderButton.minValue = 0.01;

    this.closeButton = new ImageButton(removeIcon, null);
    this.closeButton.setDefault({
      x: 500,
      y: 500,
      width: 50,
      height: 50
    });

    this.closeButton.addListener(() => {
      p5Instance.App?._removeTouchStructure(this);
    });

    this.buttons.buttons = [this.closeButton, this.sliderButton];
    this.buttons.renderInit(p5Instance);
  }

  connectToNode(node: PhysicalLeaf) {
    node.node.electricityNode = this.electricityNode;
    this.connectedNode = node;
  }

  destructor() {
    this.dead = true;
    // this.cache = {};
  }

  touch() {
    this.dirtySince = this.context.appNow();
  }

  /**
   * @throws {String} Message about dead node
   */
  dataIsDirtySince(marker) {
    if (this.dead) throw 'Trying to access dead Structure';

    return marker < this.dirtySince;
  }

  /**
   * @throws {String} Message about dead node
   */
  layoutPipes(p5Instance: TableP5) {
    if (this.dead) throw 'Trying to access dead Structure';

    if (this.connectedNode == undefined) {
      return;
    }

    const [electricityStartPoint, electricityEndPoint] =
      calculateElectricityPipePoints(
        this.center(),
        this.connectedNode.center(),
        this._pipeInterSectRadius(),
        this.connectedNode._pipeInterSectRadius(),
        this.context.Vector
      );

    if (this.electricityPipe == null) {
      this.electricityPipe = new Pipe(
        p5Instance,
        electricityStartPoint,
        electricityEndPoint,
        Settings.electricityPipeColor
      );
    }

    this.electricityPipe.init(
      p5Instance,
      electricityStartPoint,
      electricityEndPoint,
      Settings.electricityPipeColor
    );
  }

  _pipeInterSectRadius() {
    return this.shapeLayout.pieChartSize / 2;
  }

  resetConnections() {
    super.resetConnections();
  }

  /* Get the "size" of the structure, which is the value scaling value coming from the user rotation */
  getSize() {
    return this.size;
  }

  setSummary(_summary: EnergyGridSummary) {
    // console.log('Set summary', summary.coolingSatisfaction, summary.heatingSatisfaction, summary.heating, summary.cooling);
  }

  shapeRadius() {
    return this.shapeLayout.rawSize / 2;
  }

  center() {
    return this.centerPos.copy();
  }

  layout(p5Instance: p5) {
    this.buttons.active = this.active;
    let settingsSize = Settings.structureDialSize * this.baseScale;
    let baseSize = Settings.baseStructureSize * this.baseScale;
    const thickness = this.getSize() * baseSize;
    let pieChartSize = baseSize + thickness + settingsSize;

    const t = p5Instance.frameCount - this.createdTimeStamp;

    this.shapeLayout.rawSize = pieChartSize;
    this.shapeLayout.imageSize = this.imageSize;
    if (t < Settings.structureAnimationTime) {
      const animation = d3.easeElasticOut(
        p5Instance.map(t, 0, Settings.structureAnimationTime, 0, 1)
      );
      pieChartSize *= animation;
      this.shapeLayout.imageSize *= animation;
    }

    this.shapeLayout.pieChartSize = pieChartSize;
    const center = this.center();
    const rect = this.closeButton.defaultRect;
    this.electricityNode.attenuation = DataManager.getElectricityPotentialFor();
    this.closeButton.setDefault({
      ...rect,
      x: center.x - rect.width / 2 + pieChartSize / 2.0 + 30,
      y: center.y - rect.height / 2 + pieChartSize / 2.0 - 30
    });

    this.sliderButton.bounding.x = center.x + pieChartSize / 2;
    this.sliderButton.bounding.y = center.y + 1;
  }

  _drawPipes(p5Instance: TableP5) {
    p5Instance.push();

    if (this.connectedNode && this.connectedNode.node && this.electricityPipe) {
      this.electricityPipe.draw(false, 0.1);
    }

    p5Instance.pop();
  }

  drawBg(p5Instance: TableP5) {
    this.layoutPipes(p5Instance);
    this._drawPipes(p5Instance);
  }

  /**
   * @throws {String} Message about dead structure
   */
  draw(p: TableP5) {
    if (this.dead) throw 'Trying to access dead Structure';

    const center = this.center();
    const mainRadius = this.shapeLayout.pieChartSize;
    const mainThickness = 2;

    if (this.active) {
      this.buttons.draw(p);
    }

    const textAreaWidth = drawNodeText(
      p,
      center,
      this.name,
      0.0,
      0.0,
      this.electricityNode.getCurrentEffectMW(),
      this.electricityNode.scale,
      mainRadius,
      circleNodeTextInset,
      null
    );
    this.sliderButton.bounding.w = textAreaWidth;

    p.push();
    {
      p.noStroke();
      p.fill(255, 255, 255, 255);
      const circleSize = mainRadius - mainThickness + 3;
      p.arc(center.x, center.y, circleSize, circleSize, 0, Math.PI * 2);

      if (this.active) {
        p.fill(35, 35, 35, 255);
      } else {
        p.fill(20, 20, 20, 255);
      }

      p.arc(center.x, center.y, circleSize - 5, circleSize - 5, 0, Math.PI * 2);

      if (this.image != null) {
        p.push();
        const aspectRatio = this.image.width / this.image.height;
        p.translate(center.x, center.y);
        const width = this.shapeLayout.imageSize;
        const height = width / aspectRatio;
        p.image(this.image, -width * 0.5, -height * 0.5, width, height);
        p.pop();
      }
    }

    p.pop();
  }
}

export default ElectricitySource;

export class Battery extends ElectricitySource {
  constructor(
    context: any,
    p5Instance: TableP5,
    buildingTemplate: any,
    scale: number
  ) {
    super(
      context,
      p5Instance,
      buildingTemplate,
      scale,
      'battery',
      BatteryImage,
      Settings.batterySourceImageSize
    );
  }
}

export class PVCell extends ElectricitySource {
  constructor(
    context: any,
    p5Instance: TableP5,
    buildingTemplate: any,
    scale: number
  ) {
    super(
      context,
      p5Instance,
      buildingTemplate,
      scale,
      'pvcell',
      SunImage,
      Settings.pvCellSourceImageSize
    );
  }
}
