import { EctogridBuilding } from './EctogridBuilding';
import mediconImage from '../assets/medicon_transp.png';
import silvertownImage from '../assets/silvertown_transp.png';
import merwedeImage from '../assets/merwede_transp.png';
import { ExternalEnergySourceType } from 'js/Table/ExternalEnergySourceType';

const mediconTouchPolygon: [x: number, y: number][] = [
  [250, 150],
  [640, 150],
  [640, 280],
  [740, 280],
  [740, 340],
  [1000, 340],
  [1000, 430],
  [1440, 430],
  [1440, 600],
  [1050, 600],
  [1050, 720],
  [650, 720],
  [380, 700],
  [140, 600],
  [150, 450],
  [190, 400]
];

const buildingsInMediconVillage: Record<string, EctogridBuilding> = {
  203: {
    // Only heating
    buildingId: '203',
    address: 'Tunavägen 22',
    x: 300,
    y: -15,
    scale: 0.5,
    radius: 400
  },
  301: {
    // Both heating and cooling
    description: 'Main Building',

    buildingId: '301',
    address: 'Scheelevägen 2',
    x: 165,
    y: -40,
    scale: 0.5,
    radius: 400,
    connectTo: '203'
  },
  303: {
    // Only heating
    description: 'Resturant Inspira',

    buildingId: '303',
    address: 'Scheelevägen 4',
    x: 30,
    y: -30,
    scale: 0.5,
    radius: 400,
    connectTo: '302'
  },
  302: {
    // Only heating
    description: 'Audatorium',

    buildingId: '302',
    address: 'Scheelevägen 4',
    x: 25,
    y: -95,
    scale: 0.5,
    radius: 400,
    connectTo: '301'
  },
  401: {
    // Both heating and cooling
    description: 'Obducat & N-tigen',

    address: 'Scheelevägen 6',
    buildingId: '401',
    x: -40,
    y: -160,
    scale: 0.5,
    radius: 400,
    connectTo: '302'
  },
  '403': {
    address: 'Scheelevägen 10',
    buildingId: '403',
    x: -85,
    y: -115,
    scale: 0.5,
    radius: 400,
    connectTo: '401'
  },
  404: {
    // Both heating and cooling
    buildingId: '404',
    address: 'Scheelevägen 12',
    x: -182,
    y: -135,
    scale: 0.5,
    radius: 400,
    connectTo: '401'
  },
  406: {
    // Both heating and cooling
    buildingId: '406',
    address: 'Scheelevägen 14',
    x: -265,
    y: 10,
    scale: 0.5,
    radius: 400,
    connectTo: '404'
  },
  407: {
    buildingId: '407',
    address: 'Scheelevägen 14',
    x: -200,
    y: 55,
    scale: 0.5,
    radius: 400,
    connectTo: '406'
  },
  '402 A': {
    // Only cooling
    buildingId: '402 A',
    address: 'Scheelevägen 8',
    x: -145,
    y: 100,
    scale: 0.5,
    radius: 400,
    connectTo: '407'
  },
  402: {
    // Both heating and cooling
    buildingId: '402',
    address: 'Scheelevägen 8',
    x: -90,
    y: 100,
    scale: 0.5,
    radius: 400,
    connectTo: '402 A'
  },
  601: {
    // Both heating and cooling
    buildingId: '601',
    address: 'Scheelevägen 8 South',
    x: -40,
    y: 80,
    scale: 0.5,
    radius: 400,
    connectTo: '402'
  },
  R1: {
    // Uses the heat profile of the residental archetype
    buildingId: 'R1',
    address: 'Scheelevägen 5',
    x: 92,
    y: 100,
    scale: 0.3,
    radius: 400,
    connectTo: '601'
  },
  R2: {
    // Uses the heat profile of the residental archetype
    buildingId: 'R2',
    address: 'Scheelevägen 3',
    x: 150,
    y: 100,
    scale: 0.25,
    radius: 400,
    connectTo: 'R1'
  },
  R3: {
    // Uses the heat profile of the residental archetype
    buildingId: 'R3',
    address: 'Scheelevägen 1',
    x: 210,
    y: 100,
    scale: 0.35,
    radius: 400,
    connectTo: 'R2'
  },
  R4: {
    // Uses the heat profile of the residental archetype
    buildingId: 'R4',
    address: 'Scheelevägen 1',
    x: 160,
    y: 23,
    scale: 0.35,
    radius: 400,
    connectTo: '301'
  }
};

export type EctogridBuildingDefinition = {
  name: string;
  image: string;
  buildings: Record<string, EctogridBuilding>;
  scale: number;
  formFile: string;
  touchPolygon: [x: number, y: number][];
  infoOffset: [x: number, y: number];
  closeButtonCenterOffset: [x: number, y: number];
  externalEnergySource?: {
    x: number;
    y: number;
    type: ExternalEnergySourceType;
  };
};

const createBuilding = (
  name: string,
  fullName: string,
  x: number,
  y: number,
  connectTo?: string
): Record<string, EctogridBuilding> => {
  return {
    [name]: {
      buildingId: fullName,
      address: fullName,
      x: x,
      y: y,
      scale: 0.5,
      radius: 400,
      connectTo: connectTo
    }
  };
};

const EctogridBuildingDefinitions: Record<string, EctogridBuildingDefinition> =
  {
    Lund: {
      name: 'Medicon Village',
      buildings: buildingsInMediconVillage,
      image: mediconImage,
      scale: 1.5,
      formFile: 'medicon_form.json',
      touchPolygon: mediconTouchPolygon,
      infoOffset: [88, -162],
      closeButtonCenterOffset: [0, 40]
    },
    Utrecht: {
      name: 'Merwede',
      touchPolygon: [
        [-25, -20],
        [1400, -20],
        [1400, 430],
        [800, 430],
        [800, 580],
        [460, 580],
        [460, 430],
        [-25, 410]
      ],
      externalEnergySource: {
        x: 200,
        y: 200,
        type: 'aquifer'
      },
      buildings: {
        ...createBuilding('1', 'Building 1', -290, -245),
        ...createBuilding('2', 'Building 2', -20, -245, '1'),
        ...createBuilding('3', 'Building 3', 260, -275, '2'),
        ...createBuilding('4', 'Building 4', -340, 0, '1'),
        ...createBuilding('5', 'Building 5', -190, -20, '4'),
        ...createBuilding('6', 'Building 6', 0, -70, '5'),
        ...createBuilding('17', 'Building 17', 145, -20, '6'),
        ...createBuilding('7', 'Building 7', 315, 10, '17'),
        ...createBuilding('8', 'Building 8', 470, -245, '3'),
        ...createBuilding('9', 'Building 9', 480, -75, '8'),
        ...createBuilding('10', 'Building 10', 500, 50, '7'),
        ...createBuilding('11', 'Building 11', 680, -205, '8'),
        ...createBuilding('12', 'Building 12', 670, -25, '10'),
        ...createBuilding('13', 'Building 13', 835, -225, '11'),
        ...createBuilding('15', 'Building 15', 1055, -195, '13'),
        ...createBuilding('16', 'Building 16', 1075, 15, '15'),
        ...createBuilding('18', 'Building 18', 898, 45, '16')
      },
      image: merwedeImage,
      scale: 0.85,
      formFile: 'merwede_form.json',
      infoOffset: [528, 112],
      closeButtonCenterOffset: [20, 50]
    },
    London: {
      name: 'Silvertown',
      touchPolygon: [
        [-25, -20],
        [1370, -20],
        [1370, 545],
        [1670, 545],
        [1670, 765],
        [1360, 765],
        [1250, 645],
        [-25, 645]
      ],
      externalEnergySource: {
        x: 1100,
        y: 400,
        type: 'industry'
      },
      buildings: {
        ...createBuilding('1', 'Mills Quarter, plot 1', -350, -215),
        ...createBuilding('1C', 'Victoria Waterside, plot 1C', 160, -245, '3'),
        ...createBuilding('1D', 'Mills Quarter, plot 1D', -340, -40, '1'),
        ...createBuilding('1E', 'Mills Quarter, 1E', -150, -90, '1D'),
        ...createBuilding('1H', 'Victoria Waterside, plot 1H', 210, -80, '4'),
        ...createBuilding('1J', 'Silo D Quarter, plot 1J', -140, 90, '2D'),
        ...createBuilding('1L', 'Pontoon Dock, plot 1L', 35, 100, '1J'),
        ...createBuilding('2A', 'Mills Quarter, plot 2A', -190, -250, '1'),
        ...createBuilding('2B', 'Mills Quarter, plot 2B', -90, -268, '2A'),
        ...createBuilding('2D', 'Silo D Quarter, plot 2D', -340, 110, '1D'),
        ...createBuilding('3', 'Mills Quarter, plot 3', 10, -220, '2B'),
        ...createBuilding('3C', 'Victoria Waterside, plot 3C', 530, -235, '3D'),
        ...createBuilding('3D', 'Victoria Waterside, plot 3D', 525, -70, '3F'),
        ...createBuilding('3E', 'Pontoon Dock, plot 3E', 210, 85, '1L'),
        ...createBuilding('3F', 'Pontoon Dock, plot 3F', 515, 105, '3E'),
        ...createBuilding('4', 'Mills Quarter, plot 4', 15, -80, '1E'),
        ...createBuilding('4A', 'Pontoon Dock, plot 4A', 100, 300, '8'),
        ...createBuilding('4B', 'Pontoon Dock, plot 4B', 280, 270, '4A'),
        ...createBuilding('4C', 'Pontoon Dock, plot 4C', 495, 270, '4B'),
        ...createBuilding('5A', 'East Pontoon, plot 5Ai', 715, 150, '4C'),
        ...createBuilding('5B', 'East Pontoon, plot 5Bi', 998, 100, '5A'),
        ...createBuilding('5C', 'East Pontoon, plot 5C', 1190, -20, '5B'),
        ...createBuilding('5D', 'East Pontoon, plot 5D', 1200, 155, '5B'),
        ...createBuilding('6', 'Silo D Quarter, plot 6', -370, 300, '2D'),
        ...createBuilding('6A', 'Silverworks, plot 6A', 690, -235, '3C'),
        ...createBuilding('6B', 'Silverworks, plot 6B', 690, -85, '6A'),
        ...createBuilding('6C', 'Silverworks, plot 6C', 830, -225, '6A'),
        ...createBuilding('6D', 'Silverworks, plot 6D', 835, -105, '6B'),
        ...createBuilding('6E', 'Silverworks, plot 6E', 1050, -130, '6D'),
        // Note that this building just borrows data from another one as we don't have specific data yet
        ...createBuilding('6F', 'Silo D Quarter, plot 1J', 1005, -220, '6C'),
        ...createBuilding('7', 'Silo D Quarter, plot 7', -230, 380, '6'),
        ...createBuilding('8', 'Silo D Quarter, plot 8', -70, 340, '7')
      },
      image: silvertownImage,
      scale: 0.8,
      formFile: 'silvertown_form.json',
      infoOffset: [615, 332],
      closeButtonCenterOffset: [270, 20]
    }
  };

export default EctogridBuildingDefinitions;
