import React from 'react';
import classnames from 'classnames';
import View from '../../components/View/View';
import Flex from '../../components/Flex/Flex';
import './PowerProductionView.css';
import EctotableDashboardPanel from '../DashboardView/EctotableDashboardPanel';
import dashboardData from './PowerProductionDashboardData.json';
import Box from '../../components/Box/Box';
import EnergyDemandBarChart from 'js/views/PowerConsumptionView/EnergyDemandBarChart';
import Map from 'ecto-common/lib/Map/Map';
import MapMarker from 'ecto-common/lib/Map/MapMarker';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';

type PowerProductionViewProps = {
  data: any;
  className?: string;
  ectocloudMode: boolean;
  size: { width: number; height: number };
};
const mapOptions = {
  fullscreenControl: false,
  disableDefaultUI: true
};

/** Client screen showing power production. */
const PowerProductionView = ({
  data,
  className,
  ectocloudMode,
  size
}: PowerProductionViewProps) => {
  const location = data && data.location;
  const darkModeEnabled = useSyncExternalStore(
    darkModeStore.subscribe,
    darkModeStore.getSnapshot
  );

  if (ectocloudMode) {
    return (
      <div className="PowerProductionView__dashboard_content__main">
        <div className="View__title">
          <h1>Live data</h1>
        </div>
        <div className="PowerProductionView__dashboard_panel_container">
          <EctotableDashboardPanel
            panelData={dashboardData}
            contentClassName="PowerProductionView__dashboard_panel"
            showOnlyContent
          />
        </div>
      </div>
    );
  }

  return (
    <View className={classnames(className)} title="Energy Source Balance">
      <div className="PowerProductionView__content__side">
        <Box title="Location">
          {location && (
            <Map
              zoom={5}
              center={{ lat: location.latitude, lng: location.longitude }}
              options={mapOptions}
              draggable={false}
            >
              <MapMarker lat={location.latitude} lng={location.longitude} />
            </Map>
          )}
        </Box>
      </div>
      <Flex direction="column" className="PowerProductionView__content__main">
        {size.width != null && size.height != null && (
          <EnergyDemandBarChart
            darkModeEnabled={darkModeEnabled}
            data={data}
            size={size}
          />
        )}
      </Flex>
    </View>
  );
};

export default React.memo(PowerProductionView);
