/* eslint no-lone-blocks: 0 */ // --> OFF

import { EnergyGridSummary } from 'js/Table/energy_grid';
import Structure from './Structure';
import buildingIcons from '../config/buildingIcons';
import TableP5 from 'js/Table/TableP5';
import { ExternalEnergySourceType } from 'js/Table/ExternalEnergySourceType';

/**
 */
class ExternalEnergySource extends Structure {
  relativePosition: { x: number; y: number };
  showAsDots: boolean;
  type: ExternalEnergySourceType;

  constructor(
    context,
    p5Instance,
    type: ExternalEnergySourceType,
    x = 0,
    y = 0
  ) {
    let template = {
      x,
      y,
      buildingId: 'ext-' + type,
      name: type === 'aquifer' ? 'Aquifer storage' : 'Industry',
      icon:
        type === 'aquifer'
          ? buildingIcons.buildingIconConstants.aquifer
          : buildingIcons.buildingIconConstants.industry,
      scale: 1.0
    };

    super(context, p5Instance, template, 1);
    this.type = type;
    this.relativePosition = { x, y };
    this.fixedSize = true;
    this.showAsDots = false;
    this.node.outsideGrid = true;
    this.node._heatingNeedMW = 20;
    this.node._coolingNeedMW = 20;
  }

  hit() {
    return null;
  }

  setSummary(summary: EnergyGridSummary) {
    if (
      summary.heatingWithoutExternalSources >
      summary.coolingWithoutExternalSources
    ) {
      this.node._heatingNeedMW =
        summary.coolingWithoutExternalSources -
        summary.heatingWithoutExternalSources;
      this.node._coolingNeedMW = 0;
    } else {
      if (this.type === 'aquifer') {
        this.node._heatingNeedMW = 0;
        this.node._coolingNeedMW =
          summary.heatingWithoutExternalSources -
          summary.coolingWithoutExternalSources;
      } else {
        this.node._heatingNeedMW = 0;
        this.node._coolingNeedMW = 0;
      }
    }
  }

  parentMovedTo(parentCenter) {
    const newVector = this.p5Instance.createVector(
      this.relativePosition.x + parentCenter.x,
      this.relativePosition.y + parentCenter.y
    );

    this.centerPos = newVector;
    this.cache.center = newVector;
  }

  _boundaryRadius() {
    return this.cache.boundaryRadius * 0.1;
  }

  layout(p5: TableP5) {
    super.layout(p5);
    this.cache.mainRadius *= 0.6;
    this.cache.mainThickness *= 0.5;
    this.imageSize *= 0.75;
    this.cache.crustRadius = this.cache.mainRadius * 1.1;

    this.node.heatingElectricityCostMW = 0.0;
    this.node.coolingElectricityCostMW = 0.0;
  }
}

export default ExternalEnergySource;
