/**
 * The parsing of and searching in the data for each building.
 *
 * The buildings used in the grid have different heat/cold usage profiles,
 * for the ones in Ectogrid this is fetched from real data collected historically.
 * For the archetype buildings this is constructed and tweaked,
 * to both be somewhat realistic and useful in the demo.
 * Each buildings' profile reside in one file.
 */

/* eslint camelcase: 0 */ // --> OFF

import moment from 'moment';

export const HOURS_IN_YEAR = 8760;

let formData: EctoplannerForm = null;

import layout from '../../styles/variables/layout';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import standardBuildingsFormObj from './standard_buildings_form.json';

const sumArrays = (arr: number[], arr2: number[]): number[] => {
  if (arr == null || arr.length === 0) {
    return arr2;
  }

  if (arr2 == null || arr2.length === 0) {
    return arr;
  }

  let sum: number[] = [];

  for (let i = 0; i < arr.length; i++) {
    sum.push(arr[i] + arr2[i]);
  }

  return sum;
};

const standardBuildingsForm =
  standardBuildingsFormObj as any as EctoplannerForm;

type BuildingEnergyData = {
  heating: number[];
  cooling: number[];
};

let buildingEnergyData: Record<string, BuildingEnergyData> = {};

function populateBuildingEnergyData(newFormData: EctoplannerForm) {
  for (let building of newFormData.buildings) {
    const heatingArray = sumArrays(
      building.params.checkCalcSpaceHeat
        ? building.params.spaceHeatProfile
        : [],
      building.params.checkCalcDhw ? building.params.dhwProfile : []
    );

    const coolingArray = sumArrays(
      building.params.checkCalcSpaceCool
        ? building.params.spaceCoolProfile
        : [],
      building.params.checkCalcProcessCool
        ? building.params.processCoolProfile
        : []
    );
    buildingEnergyData[building.name] = {
      heating: heatingArray?.length === 0 ? null : heatingArray,
      cooling: coolingArray?.length === 0 ? null : coolingArray
    };
  }
}

populateBuildingEnergyData(standardBuildingsForm);

const heatingData = {};
const coolingData = {};

const daysAYear = 365;
const hoursADay = 24;

const HOUR = 1000 * 60 * 60; // an hour in milisecs

export type EnergyNeedType = {
  time: number;
  heatingNeedMW: number;
  coolingNeedMW: number;
  electricityGenerationMW: number;
};

export type EnergyNeedAndPotentialElectricityType = {
  time: number;
  heatingNeedMW: number;
  coolingNeedMW: number;
  electricityPotential: number;
};

const getEnergyForBuildingWithIdKW = (
  id: string,
  kind: 'heating' | 'cooling'
): number[] => {
  const energyDataForBuilding =
    kind === 'heating' ? heatingData[id]?.data : coolingData[id]?.data;

  if (
    (energyDataForBuilding == null || energyDataForBuilding.length == 0) &&
    formData != null
  ) {
    const buildingData = buildingEnergyData[id];

    if (buildingData) {
      if (kind === 'heating') {
        return buildingData.heating;
      }

      return buildingData.cooling;
    } else if (
      id !== 'pvcell' &&
      id !== 'accumulatortank' &&
      id !== 'ext-aquifer' &&
      id !== 'ext-industry'
    ) {
      console.error('Failed to find building with id:', id);
    }
  }

  return energyDataForBuilding;
};

/**
 * Get the heating and cooling need for a particular building at a given time.
 */
const _getNeedForTime = (
  service_number: string,
  time,
  multiplier = 1
): EnergyNeedAndPotentialElectricityType => {
  let momentDate = moment(time);

  let dayOfYear = Math.min(365, momentDate.dayOfYear() - 1);
  const hour = time.getHours() - 1;
  let index = dayOfYear * 24 + hour;

  const heatingForBuildingKW = getEnergyForBuildingWithIdKW(
    service_number,
    'heating'
  );
  const coolingForBuildingKW = getEnergyForBuildingWithIdKW(
    service_number,
    'cooling'
  );

  const heatingForThisHour = heatingForBuildingKW
    ? heatingForBuildingKW[index]
    : 0;
  const coolingForThisHour = coolingForBuildingKW
    ? coolingForBuildingKW[index]
    : 0;

  return {
    time: hour,
    heatingNeedMW: (heatingForThisHour * multiplier) / 1000.0,
    coolingNeedMW: (coolingForThisHour * multiplier) / 1000.0,
    electricityPotential: getElectricityPotentialFor(time) * multiplier
  };
};

/**
 * Get the heating and cooling needs right now.
 */
export function getCurrentNeedFor(
  service_number: string
): EnergyNeedAndPotentialElectricityType {
  return _getNeedForTime(service_number, new Date(Date.now()));
}

export function getElectricityPotentialFor(time = new Date()) {
  const hour = time.getHours();

  const hourlyAttenuation = [
    0.0,
    0,
    0,
    0,
    0,
    0.2,
    0.25,
    0.48, // 00:00 => 07:00
    0.55,
    0.65,
    0.75,
    1.0,
    1.0,
    1.0,
    0.9,
    0.9, // 08:00 => 15:00
    0.8,
    0.7,
    0.6,
    0.5,
    0.2,
    0,
    0,
    0 // 16:00 => 23:00
  ];

  return hourlyAttenuation[hour];
}

/**
 * Get the heating and cooling needs per hour for a number of hours back (from current).
 */
export function getHistoricNeedFor(
  service_number,
  numberOfHours
): EnergyNeedAndPotentialElectricityType[] {
  const hours: EnergyNeedAndPotentialElectricityType[] = [];
  const now = Date.now();

  for (let hoursAgo = numberOfHours; hoursAgo >= 0; hoursAgo--) {
    hours.push(
      _getNeedForTime(service_number, new Date(now - hoursAgo * HOUR))
    );
  }

  return hours;
}

/**
 * Get the (predicted) heating and cooling needs per hour for a number of hours from now.
 */
export function getPredictiveNeedFor(
  service_number,
  numberOfHours
): EnergyNeedAndPotentialElectricityType[] {
  const hours: EnergyNeedAndPotentialElectricityType[] = [];
  const now = Date.now();

  for (let hoursForward = 0; hoursForward <= numberOfHours; hoursForward++) {
    const randomMultiplier = 1 + 0.2 * Math.random() - 0.1;

    hours.push(
      _getNeedForTime(
        service_number,
        new Date(now + hoursForward * HOUR),
        randomMultiplier
      )
    );
  }

  return hours;
}

function _dateFromDay(year, day) {
  let date = new Date(year, 0); // initialize a date in `year-01-01`

  return new Date(date.setDate(day)); // add the number of days
}

/**
 * Get the summarised heating and cooling needs for a number of buildings over the year.
 *
 * Combines the heating and cooling needs for several buildings to describe a similar data structure
 * that can be used for aggregate cluster of buildings (Medicon Village).
 */
export function combineNeedsForSeveral(structures) {
  type EnergyData = {
    data: number[];
    scale: number;
  };

  const heatingDatas: EnergyData[] = [];
  const coolingDatas: EnergyData[] = [];

  for (let i = 0; i < structures.length; i++) {
    const structure = structures[i];
    const buildingId = structure.buildingId;
    const scale = structure.templateScale;

    if (structure.node.outsideGrid) {
      continue;
    }

    const _heatingDataForStructureKW = getEnergyForBuildingWithIdKW(
      buildingId,
      'heating'
    );
    const _coolingDataForStructureKW = getEnergyForBuildingWithIdKW(
      buildingId,
      'cooling'
    );

    if (_heatingDataForStructureKW) {
      heatingDatas.push({ data: _heatingDataForStructureKW, scale });
    }
    if (_coolingDataForStructureKW) {
      coolingDatas.push({ data: _coolingDataForStructureKW, scale });
    }
  }

  const combinedHeatingDataKW: number[] = new Array(HOURS_IN_YEAR).fill(0);
  const combinedCoolingDataKW: number[] = new Array(HOURS_IN_YEAR).fill(0);

  for (let i = 0; i < HOURS_IN_YEAR; i++) {
    for (let heatIdx = 0; heatIdx < heatingDatas.length; heatIdx++) {
      const heatingDataForStructure = heatingDatas[heatIdx];

      combinedHeatingDataKW[i] +=
        heatingDataForStructure.data[i] * heatingDataForStructure.scale;
    }

    for (let coolIdx = 0; coolIdx < coolingDatas.length; coolIdx++) {
      const coolingDataForStructure = coolingDatas[coolIdx];
      combinedCoolingDataKW[i] +=
        coolingDataForStructure.data[i] * coolingDataForStructure.scale;
    }
  }

  return {
    heating: { data: combinedHeatingDataKW },
    cooling: { data: combinedCoolingDataKW }
  };
}

/**
 * Get the heating and cooling needs for a number of buildings over the year.
 *
 * Combines the heating and cooling needs for several buildings over a 365 day period.
 * As the data is static and describe the needs over a typical year (2015)
 * there is no distinction here on whether the data is percieved as predicted or historical.
 */
export function getAnnualNeed(structures) {
  const heatingArrayGrouped = [];
  const coolingArrayGrouped = [];
  let groupedArrayIndex = 0;

  const heatingArrayPerMonth = [];
  const coolingArrayPerMonth = [];

  let includeDayInGroupedArray = false;
  let month;

  const addStructure = (structure, dayIndex: number, hourIndex: number) => {
    const heatingDataForStructureKW = getEnergyForBuildingWithIdKW(
      structure.buildingId,
      'heating'
    );
    const coolingDataForStructureKW = getEnergyForBuildingWithIdKW(
      structure.buildingId,
      'cooling'
    );
    const size = structure.getSize();
    const specificIndex = dayIndex * 24 + hourIndex;

    if (
      heatingDataForStructureKW &&
      specificIndex < heatingDataForStructureKW.length
    ) {
      const scaledHeatingForStructureThisHour =
        (heatingDataForStructureKW[specificIndex] * size) / 1000.0;

      if (includeDayInGroupedArray) {
        heatingArrayGrouped[groupedArrayIndex][hourIndex] +=
          scaledHeatingForStructureThisHour;
      }
      heatingArrayPerMonth[month - 1] += scaledHeatingForStructureThisHour;
    }

    if (
      coolingDataForStructureKW &&
      specificIndex < coolingDataForStructureKW.length
    ) {
      const scaledCoolingForStructureThisHour =
        (coolingDataForStructureKW[specificIndex] * size) / 1000.0;

      if (includeDayInGroupedArray) {
        coolingArrayGrouped[groupedArrayIndex][hourIndex] +=
          scaledCoolingForStructureThisHour;
      }
      coolingArrayPerMonth[month - 1] += scaledCoolingForStructureThisHour;
    }
  };

  for (let i = 0; i < daysAYear; i++) {
    includeDayInGroupedArray =
      !layout.hourConsumptionDensity || i % layout.hourConsumptionDensity === 0;

    if (includeDayInGroupedArray) {
      heatingArrayGrouped[groupedArrayIndex] = [];
      coolingArrayGrouped[groupedArrayIndex] = [];
    }

    const date = _dateFromDay(2015, i + 2);

    month = date.getUTCMonth() + 1; //months from 1-12

    if (!heatingArrayPerMonth[month - 1]) {
      heatingArrayPerMonth[month - 1] = 0;
    }
    if (!coolingArrayPerMonth[month - 1]) {
      coolingArrayPerMonth[month - 1] = 0;
    }

    for (let j = 0; j < hoursADay; j++) {
      if (includeDayInGroupedArray) {
        heatingArrayGrouped[groupedArrayIndex][j] = 0;
        coolingArrayGrouped[groupedArrayIndex][j] = 0;
      }

      structures.forEach((structure) => addStructure(structure, i, j));
    }

    if (includeDayInGroupedArray) {
      groupedArrayIndex++;
    }
  }

  return {
    heating: heatingArrayGrouped,
    cooling: coolingArrayGrouped,
    monthsHeating: heatingArrayPerMonth,
    monthsCooling: coolingArrayPerMonth
  };
}

/**
 * Set the data for buildings that is not initiated at startup.
 */
export function pushDataForStructure(buildingId, heating, cooling) {
  heatingData[buildingId] = heating;
  coolingData[buildingId] = cooling;
}

export function setDataManagerLocationData(newFormData: EctoplannerForm) {
  formData = newFormData;

  populateBuildingEnergyData(newFormData);
}
