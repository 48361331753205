import TableP5 from 'js/Table/TableP5';
import SquareButton from 'js/Table/square_button';

export default class ButtonManager {
  buttons: SquareButton[] = [];
  active = true;

  constructor(buttons: SquareButton[] = []) {
    this.buttons = buttons;
  }

  renderInit(p5Instance: TableP5) {
    for (const button of this.buttons) {
      button.renderInit(p5Instance);
    }
  }

  mouseReleased(x: number, y: number) {
    if (!this.active) {
      return;
    }

    for (const button of this.buttons) {
      if (button.menu?.mouseReleased(x, y) || button.mouseReleased(x, y)) {
        return true;
      }
    }
    return false;
  }

  mousePressed(x: number, y: number) {
    if (!this.active) {
      return;
    }

    for (const button of this.buttons) {
      if (button.menu?.mousePressed(x, y) || button.mousePressed(x, y)) {
        return true;
      }
    }
    return false;
  }

  mouseDragged(x: number, y: number) {
    if (!this.active) {
      return;
    }

    for (const button of this.buttons) {
      if (button.menu?.mouseDragged(x, y) || button.mouseDragged(x, y)) {
        return true;
      }
    }
    return false;
  }

  touchStarted(touch: any, touchedCallback = (_button: any) => {}) {
    if (!this.active) {
      return;
    }

    for (const button of this.buttons) {
      if (button.menu?.touchStarted(touch, touchedCallback)) {
        return true;
      } else if (button.touchStarted(touch.clientX, touch.clientY)) {
        touchedCallback(button);
        return true;
      }
    }
    return false;
  }

  draw(p5Instance: TableP5) {
    if (!this.active) {
      return;
    }

    for (const button of this.buttons) {
      if (button.visible) {
        button.draw(p5Instance);
      }
    }
  }
}
